import { ChallengeButton } from "../../components/atoms/Buttons/ChallengeButton";
import { useState } from "react";
import style from "./Home.module.css";
import springMockup from "../../assets/images/springleaf 1.png";
import autumnMockup from "../../assets/images/autumnleaf 2.png";
import FcLogo from "../../assets/images/FC-black.png";
import { Modal } from "../../components/organisms/Modal";
import men from "../../assets/images/_0002.png";

export const Home = () => {
  const [variant, setVariant] = useState("spring");
  const [open, setOpen] = useState(false);

  const openLink = (url) => {
    window.open(url);
  };

  return (
    <div className={style.main}>
      <Modal width="medium" open={open} onClose={() => setOpen(false)} />
      <img className={style.menImg} src={men} alt="men" />
      <div
        className={`${style.layer} ${
          variant === "spring" ? style.spring : style.autumn
        }`}
      />
      <div
        className={` ${style.mockupContainer} ${
          variant === "spring" && style.slide
        }`}
      >
        <div className={style.imageContainer}>
          <img className={style.image} src={springMockup} alt="Mockup spring" />
        </div>
        <div className={style.imageContainer}>
          <img className={style.image} src={autumnMockup} alt="Mockup autumn" />
        </div>
      </div>
      <div className={style.container}>
        <h1 className={style.title}>
          Bienvenue chez
          <br /> <span className={style.linear}>France Challenges</span> ✌🏼
        </h1>
        <div className={style.buttonsContainer}>
          <div className={style.buttonTextContainer}>
            <p className={style.paragraph}>
              Pour participer à l'opération du PRINTEMPS 2025 (du 3 Mars au 4
              Mai), veuillez cliquer ici 👇🏼
            </p>
            <ChallengeButton
              variant="spring"
              onMouseEnter={() => setVariant("spring")}
              isNew={true}
              onClick={() => openLink(process.env.REACT_APP_SPRING_URL)}
            >
              Printemps 2025 🍃
            </ChallengeButton>
          </div>
          <div className={style.buttonTextContainer}>
            <p className={style.paragraph}>
              Le challenge de l'automne 2024 est clos mais l'accès aux
              ressources pédagogiques reste possible (jusqu'à fin juillet 2025)
              pour les classes précédemment inscrites. 👇🏼
            </p>
            <ChallengeButton
              variant="autumn"
              onMouseEnter={() => setVariant("autumn")}
              onClick={() => openLink(process.env.REACT_APP_AUTUMN_URL)}
              isNew={false}
            >
              Automne 2024 🍂
            </ChallengeButton>
          </div>
        </div>
        <div className={style.logoContainer}>
          <img src={FcLogo} alt="Logo FC" style={{ opacity: "0.7" }} />
          <p className={style.credentials}>
            France Challenges - {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};
